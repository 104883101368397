<template>
  <div class="affiliatePage">
    <div class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"
          ><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link
        >
      </div>
      <div
        class="p-2 w-100 bg-light main-content align-self-stretch"
        style="min-height: calc((100vh - 140px) - 50px)"
      >
        <div
          class="
            bgwhitealpha
            text-secondary
            shadow-sm
            rounded
            p-1
            px-2
            xtarget
            col-lotto
            d-flex
            flex-row
            mb-1
            pb-0
          "
        >
          <div class="lotto-title">
            <h4><i class="fas fa-thumbs-up"></i> แนะนำเพื่อน</h4>
          </div>
        </div>

        <div class="row px-0 mb-1 mx-0">
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="far fa-handshake" />
              </div>
              ภาพรวม
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/members"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="fas fa-chalkboard-teacher" />
              </div>
              ที่สมัคร
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/revenue"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="fas fa-money-check-alt" />
              </div>
              รายได้
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/withdraw"
              class="
                btn-af btn btn-outline-primary btn-block
                d-flex
                flex-column
                active
              "
            >
              <div class="affiliate-icon">
                <i class="fas fa-hand-holding-usd" />
              </div>
              ถอนรายได้
            </router-link>
          </div>
        </div>
        <div class="w-100 my-2 border-bottom"></div>

        <div
          class="
            bgwhitealpha
            text-secondary
            shadow-sm
            rounded
            p-2
            mb-1
            xtarget
            col-lotto
          "
        >
          <h4><i class="fas fa-file-invoice-dollar"></i> แจ้งถอนรายได้</h4>
          <hr />
          <p class="alert alert-primary text-center">
            รายได้ ระบบแนะนำ จะถอนเข้าเป็นเงินเครดิต
            หากสงสัยโปรดติดต่อเอเย่นต์ที่ท่านสมัครสมาชิก
          </p>
          <div class="text-center">
            <b>รายได้ปัจจุบัน - คงเหลือ</b><br />
            <h4 class="thb text-primary">฿ {{ totalIncomeBalance }}</h4>
          </div>
          <div class="text-center my-3">
            <div class="form-login form-horizontal">
              <input type="hidden" name="afwithdraw" value="1" />
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"
                    >ต้องการถอน</span
                  >
                </div>
                <input
                  type="number"
                  class="form-control"
                  name="amount"
                  placeholder="ระบุจำนวนเงิน"
                  aria-describedby="basic-addon1"
                  v-model="amount"
                />
              </div>
              <button class="btn btn-primary btn-block" @click="submit()">
                <i class="fas fa-hand-holding-usd"></i> แจ้งถอนรายได้
              </button>
            </div>
          </div>
        </div>
        <div class="bg-white text-secondary shadow-sm rounded pt-2 pb-1 px-2 mb-1">
          <h5><i class="fas fa-history"></i> แนะนำเพื่อนประวัติการถอนล่าสุด</h5>
        </div>
        <div class="bg-white text-secondary shadow-sm rounded py-2 px-1 mb-5">
            <table class="datatable table table-hover table-striped table-sm" id="invitemembers">
                <thead>
                <tr>
                    <th class="datetime text-left"><span>วัน-เวลา</span><span>ที่ถอน</span></th>
                    <th class="text-right">รายได้</th>
                    <th class="totalbet text-right"><span>คงเหลือ</span></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="historyStageAf === null || historyStageAf === 'ไม่พบรายการ'"><td colspan="3" class="text-center"><span class="text-primary">ไม่มีรายการ</span></td></tr>
                <tr class="" v-for="(item, index) in pageOfItems" :key="index" v-else>
                  <td class="datetime text-left">{{ item.date }}</td>
                  <td class="totalbet text-right">{{ Number(item.Amount).toLocaleString('en') }}</td>
                  <td class="totalbet text-right">{{ Number(item.balance).toLocaleString('en') }}</td>
                </tr>
                </tbody>
            </table>
            <div class="text-center">
              <jw-pagination :items="historyStageAf" @changePage="onChangePage" v-if="!(historyStageAf === null || historyStageAf === 'ไม่พบรายการ')"></jw-pagination>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // inquiryBalanceAffiliateService,
  withdrawalAffiliateService,
  inquiryStagementAffiliateService,
  affiliateOverallService
} from '@/services'
import { mapActions } from 'vuex'
import JwPagination from 'jw-vue-pagination'
import moment from 'moment'
export default {
  components: { JwPagination },
  async created () {
    this.setLoading(true)
    const resp = await affiliateOverallService()
    if (resp.status_code === 'SUCCESS') {
      this.totalIncomeBalance = resp.data.currentIncome
    }
    this.setLoading(false)
  },
  data: () => {
    return {
      totalIncomeBalance: '0.00',
      lastUpdateDate: moment(),
      amount: null,
      historyStageAf: null,
      pageOfItems: []
    }
  },
  async mounted () {
    const resp = await inquiryStagementAffiliateService()
    if (resp.status_code === 'SUCCESS') {
      this.historyStageAf = resp.data
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    async submit () {
      if (this.amount > 0) {
        this.setLoading(true)
        await withdrawalAffiliateService(this.amount)
        const resp = await affiliateOverallService()
        if (resp.status_code === 'SUCCESS') {
          this.totalIncomeBalance = resp.data.currentIncome
        }
        this.setLoading(false)
      }
    },
    async getStagement () {
    },
    onChangePage (pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems
    }
  }
}
</script>

<style>
</style>
